import { Box, FormHelperText, Container, Typography, Autocomplete } from '@mui/material';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState, useRef } from 'react';
import { timezones } from '../../constants/timezones';
import { useField } from 'formik';
import type { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

interface AllocateDatesProps {
    formikStartDate: string;
    formikEndDate: string;
    formikTimezoneValue?: string;
    title?: string;
    description?: string;
    datetime?: boolean;
    formikDateRange?: string;
    descriptionVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'caption';
    titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'caption';
    datesClass?: string;
    containerSpacing?: number;
    startEndDateColumns?: number;
    timeZoneColumns?: number;
    useTitles?: boolean;
    showTimeZone?: boolean;
    isEdit?: boolean;
    disabled?: boolean;
}

const dateTimeFormat = 'YYYY-MM-DDTHH:mmZ';

function geObjectValue(city: string) {
    const res = Object.values(timezones).find((v) => {
        if (v.includes(city)) {
            return v;
        }
        return undefined;
    });
    return res;
}
const dateCall = () => {
    return Date.now();
};

let currentTimestamp = dateCall();

const userTimeZone = moment.tz.guess();
const zoneValue = geObjectValue(userTimeZone.split('/')[1]);
let defaultTimeZone: string | undefined;
if (userTimeZone) {
    defaultTimeZone = timezones[userTimeZone] ? timezones[userTimeZone] : zoneValue;
}
if (!defaultTimeZone) {
    defaultTimeZone = timezones['Europe/London'];
}

function AllocateDates({
    title,
    description,
    datetime,
    formikDateRange,
    formikStartDate,
    formikEndDate,
    titleVariant,
    descriptionVariant,
    formikTimezoneValue,
    datesClass,
    containerSpacing = 1,
    startEndDateColumns = 4,
    timeZoneColumns = 4,
    useTitles,
    showTimeZone = true,
    isEdit,
    disabled = false,
}: AllocateDatesProps): any {
    const [startDateField, startDateFieldMeta, startDateFieldHelpers] = useField(formikStartDate);
    const [endDateField, endDateFieldMeta, endDateFieldHelpers] = useField(formikEndDate);
    const [tzField, , tzFieldHelpers] = useField(formikTimezoneValue || 'timeZoneField');
    const { setValue: tzSetValue } = tzFieldHelpers;
    const [dateRangeField, dateRangeFieldMeta, dateRangeFieldHelpers] = useField(formikDateRange || 'dateRangeField');
    const [minDate, setMinDate] = useState(moment().utc());
    const { setValue: setStartFieldValue } = startDateFieldHelpers;

    const calledOnce = useRef(false);
    const startDateTimeStamp = moment(startDateField.value).unix() * 1000;
    let timeStampCompare = (timeStamp1: number, timeStamp2: number) => {
        return timeStamp1 < timeStamp2;
    };

    useEffect(() => {
        if (!calledOnce.current && startDateField.value !== '') {
            if (timeStampCompare(startDateTimeStamp, currentTimestamp) && !isEdit) {
                let currentMoment = moment(currentTimestamp).toDate().getTime();
                setStartFieldValue(currentMoment);
            }
            const initialDate = datetime ? moment(startDateField.value) : moment(startDateField.value).utc(true);
            initialDate ? setMinDate(initialDate) : setMinDate(minDate);
            calledOnce.current = true;
        }
        if (formikTimezoneValue && (tzField.value === '' || !tzField.value)) {
            tzSetValue(defaultTimeZone);
        }
    }, [
        datetime,
        formikTimezoneValue,
        tzField.value,
        minDate,
        setMinDate,
        tzSetValue,
        startDateField.value,
        setStartFieldValue,
        startDateTimeStamp,
        isEdit,
    ]);

    const handleTimeZone = (timezoneOption: string) => {
        let timezone;
        timezone = Object.keys(timezones).find((key) => timezones[key] === timezoneOption);
        if (!timezone) {
            timezone = 'Europe/London';
        }

        tzFieldHelpers.setValue(timezoneOption);
        startDateFieldHelpers.setValue(moment.tz(startDateField.value, timezone).format(dateTimeFormat));
        endDateFieldHelpers.setValue(moment.tz(endDateField.value, timezone).format(dateTimeFormat));
    };

    const preventKeyboardInput = (e: any) => {
        e.preventDefault();
    };
    const handleDateRangeChange = (range: DateRange<moment.Moment>) => {
        dateRangeFieldHelpers.setValue(range);
        if (range[0] && range[0].isValid() && range[1] && range[1].isValid()) {
            startDateFieldHelpers.setValue(moment(range[0]).toDate().getTime());
            endDateFieldHelpers.setValue(moment(range[1]).endOf('day').toDate().getTime());
        }
    };

    return (
        <Container disableGutters>
            <Typography variant={titleVariant ? titleVariant : 'h3'} gutterBottom>
                {title}
            </Typography>
            <Typography variant={descriptionVariant ? descriptionVariant : 'body1'} sx={{ marginBottom: 2 }}>
                {description}
            </Typography>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale='en-US'
                localeText={{
                    start: 'Start Date',
                    end: 'End Date',
                }}>
                {!datetime && formikDateRange && (
                    <Stack spacing={3} className={datesClass}>
                        <DesktopDateRangePicker
                            minDate={minDate}
                            value={dateRangeField.value}
                            onChange={(value) => {
                                dateRangeFieldHelpers.setValue(value);
                                handleDateRangeChange(value);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextFieldWrapper
                                        onKeyDown={preventKeyboardInput}
                                        className='input-text'
                                        {...startProps}
                                    />
                                    <Box sx={{ mx: 2 }}> </Box>
                                    <TextFieldWrapper
                                        onKeyDown={preventKeyboardInput}
                                        className='input-text'
                                        {...endProps}
                                    />
                                </React.Fragment>
                            )}
                        />
                        <FormHelperText error={true}>{dateRangeFieldMeta.error}</FormHelperText>
                    </Stack>
                )}
                {datetime && (
                    <Grid container spacing={containerSpacing} className={datesClass}>
                        <Grid item xs={startEndDateColumns}>
                            {useTitles && <Typography variant='body1'> Start Date</Typography>}
                            <DesktopDateTimePicker
                                data-testid={'datePicker'}
                                label={!useTitles && 'Start Date'}
                                minDate={minDate}
                                ampm={false}
                                disabled={
                                    isEdit && timeStampCompare(startDateTimeStamp, currentTimestamp) ? true : false
                                }
                                disablePast
                                value={moment(startDateField.value)}
                                onChange={(date) => startDateFieldHelpers.setValue(date?.format(dateTimeFormat))}
                                slotProps={{
                                    textField: {
                                        onKeyDown: preventKeyboardInput,
                                        className: 'input-text',
                                    },
                                }}
                            />
                            <FormHelperText error={true}>{startDateFieldMeta.error}</FormHelperText>
                        </Grid>
                        <Grid item xs={startEndDateColumns}>
                            {useTitles && <Typography variant='body1'> End Date</Typography>}
                            <DesktopDateTimePicker
                                disabled={disabled}
                                label={!useTitles && 'End Date'}
                                minDate={minDate}
                                ampm={false}
                                value={moment(endDateField.value)}
                                onChange={(date) => endDateFieldHelpers.setValue(date?.format(dateTimeFormat))}
                                slotProps={{
                                    textField: {
                                        onKeyDown: preventKeyboardInput,
                                        className: 'input-text',
                                    },
                                }}
                            />
                            <FormHelperText error={true}>{endDateFieldMeta.error}</FormHelperText>
                        </Grid>
                        {showTimeZone && (
                            <Grid item xs={timeZoneColumns}>
                                {useTitles && <Typography variant='body1'>Time Zone</Typography>}
                                <Autocomplete
                                    options={Object.values(timezones)}
                                    getOptionLabel={(label) => label}
                                    id='timezone'
                                    className='input-text'
                                    value={tzField.value || defaultTimeZone}
                                    onChange={(event, value) => {
                                        handleTimeZone(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextFieldWrapper
                                            {...params}
                                            label={!useTitles && 'Time Zone'}
                                            variant='outlined'
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </LocalizationProvider>
        </Container>
    );
}

export { AllocateDates };
