import { useState } from 'react';
import {notificationType} from './../../../../src/types/notifications'
import { defaultNotifState } from './../../../constants/currency-constants';
import { FriendReferralForm } from './FriendReferralForm';
import { Form, Formik } from 'formik';
import { saveConfig } from '../../../utils/s3FileUtils';
import { useParams } from "react-router-dom";
import { Notification } from '../../../common/Notification/Notification';
import { referralRewardsValidationSchema } from './FriendReferralValidationSchema'
import {friendReferraType, referralFormPropType, RefProperities } from './constants/referralRewards-constants'

export const referralFormInitialValues: referralFormPropType = {
    referrerBox: false,
    refereeBox: false,
    referralRewards: {
        referrer: {
            amount: 0,
            currency: '',
            sendEmail: false
        },
        referee: {
            amount: 0,
            currency: '',
            sendEmail: false
        },

    }
};

const FriendReferral = ({config}: friendReferraType) => {
    const [notificationState, setNotificationState] = useState<notificationType>(defaultNotifState);
    const filterRefValues = (refRewardsValues: RefProperities) => {
        const { amount, currency, sendEmail = false } = refRewardsValues;
        return { amount, currency, sendEmail };
    }
    const updatedConfigValues = (formikValues) => {
        let tempConfig = config;
        if (Object.keys(formikValues?.referralRewards).length > 0) {
            tempConfig.referralRewards = formikValues.referralRewards
            for (const key in formikValues.referralRewards) {
                tempConfig.referralRewards[key] = filterRefValues(formikValues.referralRewards[key]);
            }
        } else {
            delete tempConfig?.referralRewards
        }

        if (!formikValues?.referrerBox && tempConfig?.referralRewards?.referrer) {
            delete tempConfig?.referralRewards.referrer
        }
        if (!formikValues?.refereeBox && tempConfig?.referralRewards?.referee) {
            delete tempConfig?.referralRewards.referee
        }
        return tempConfig
    }
    let urlParams = useParams();
    return (
        <>
        <Formik
                initialValues={referralFormInitialValues}
                enableReinitialize
                validationSchema={referralRewardsValidationSchema}
                onSubmit={async (values) => {
                    try {
                        await saveConfig({ urlParams, submitData: updatedConfigValues(values), setNotificationState });
                        setNotificationState({
                            open: true,
                            title: 'Referral settings were updated successfully',
                            content: 'Referral settings were saved!',
                            level: 'success',
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }}>
                <Form>
                <>
                <Notification
                setNotificationState={setNotificationState}
                notificationState={notificationState}
                />
                <FriendReferralForm
                    config={config}
                />
                </>
                </Form>
            </Formik>
        </>
    )
}

export { FriendReferral }

